<template>
  <div>
    <Card>
      <template #header>Teklif İzinleri</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addFileModal = true"
              >Yeni izin</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Teklif İzinleri Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i>Yeni İhale Bedeli</h4>
      </template>

      <div class="form-group">
        <label for="user">Kullanıcı</label>
        <select name="user" id="user" class="form-control" v-model="user">
          <option value="" selected>Seçiniz</option>
          <option value="1">Diğer</option>
        </select>
      </div>
      <div class="form-group">
        <label for="reaching_channel">Ulaştığı Kanal</label>
        <select
          name="reaching_channel"
          id="reaching_channel"
          class="form-control"
          v-model="reaching_channel"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Diğer</option>
        </select>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addFileModal = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "systemUsers",
  data() {
    return {
      addFileModal: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İzin Sahibi", value: "user" },
        { text: "Ulaştığı Kanal", value: "reaching_channel" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",

      user: "",
      reaching_channel: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    save() {
      let data = {
        user: this.user,
        reaching_channel: this.reaching_channel,
        user_token: this.myUser,
        advert_id: this.$route.params.id,
      };
      this.$ApiService
        .post("advert/bidclearances", data)
        .then((res) => {
          this.$generateNotification(this, "success", "İzin Eklenmiştir.");
          this.getList();
          (this.user = ""),
            (this.reaching_channel = ""),
            (this.addFileModal = false);
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "İzin Eklenemedi.");
        });
    },
    getList() {
      this.loading = true;
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `advert/bidclearances?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&advert_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.status.code == 200) {
            this.items = res.response.data;
            this.totalItems = res.response.data.length;

            this.pagination = {
              current_page: 1,
              total_page: Math.ceil(res.response.data.length / 10),
              totalItems: this.totalItems,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
